"use client"
import Link from "next/link"
import { supabase_public } from "../../lib/supabaseClient"
import {
  Bell,
  CircleUser,
  Home,
  LineChart,
  Menu,
  Package,
  Package2,
  Search,
  ShoppingCart,
  Users,
} from "lucide-react"

import { Badge } from "../../@/components/ui/badge"
import { Button } from "../../@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../@/components/ui/dropdown-menu"
import { Input } from "../../@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "../../@/components/ui/sheet"
import { FaTiktok, FaInstagram, FaTwitter, FaDiscord, FaArrowLeft } from 'react-icons/fa6';
import { FaClipboardList, FaCaretSquareUp, FaCartArrowDown, FaCaretDown, FaChevronDown, FaChevronUp, FaArrowAltCircleUp, FaRegArrowAltCircleUp} from "react-icons/fa";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { useEffect, useState, useContext } from "react"
import { PageContext } from "../../contexts/pageContext"
import UserConversations from '../UserConversations';
import { TbUsers, TbUserSearch } from "react-icons/tb";
import { MdLogout, MdPerson, MdSearch, MdArrowRight, MdArrowUpward } from "react-icons/md";
import AuthPrompt from "../AuthPrompt";
import { ScrollArea } from "../../@/components/ui/scroll-area"
import { useNavigate, useLocation } from 'react-router-dom';
import CategoriesList from "./CategoriesList";
import posthog from "posthog-js"
import { useRouter } from 'next/router'

export function AuthShell({loggedInUsername, userid, sessionUUID, showAuthPrompt, setShowAuthPrompt, topicSelected, feedbackOpened, setFeedbackOpened, children, profile }) {

  const context = useContext(PageContext)
  const [isOpen, setIsOpen] = useState(true);
  const [session, setSession] = useState(null)
  const [email, setEmail] = useState(null)

  const [onboard, setOnboard] = useState(false);



  // const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [status, setStatus] = useState(null)
  const [showTooltip, setShowTooltip] = useState(false);

  const buttonStyle = "hover:bg-gray-200 rounded-xl cursor-pointer active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600"
  const router = useRouter();

  useEffect(() => {
    if (profile?.created) {
      const createdDate = new Date(profile?.created);
      const now = new Date();
      const timeDifference = Math.abs(now.getTime() - createdDate.getTime());
      const twoMinutes = 2 * 60 * 1000; // one minutes in milliseconds
      if (timeDifference <= twoMinutes) {
        setOnboard(true);
      }
    }
  }, [profile?.created]);

  useEffect(() => {
    async function fetchAllData() {
        const { data, error } = await supabase_public.auth.getSession();
        if (error) {
            console.log("error retrieving session");
            // setShowAuthPrompt(true);
            return;
        }


        if (data.session && data.session.expires_at * 1000 < Date.now()) {
            console.log(data.session.expires_at * 1000, Date.now())
            supabase_public.auth.signOut();
            setSession(null);
            console.log("session expired")
            // setShowAuthPrompt(true);
            return;
        }

        setSession(data);
        const email = data?.session?.user?.email;
        if (email) {
            // Set the email
            setEmail(email);
        }
        // if (!data || data.session == null) {
        //     setShowAuthPrompt(true);
        // }
    }
    fetchAllData();
}, [userid]); // Empty dependency array ensures this runs once on mount

  const handleSignOut = async () => {
    console.log("signing out")
    await supabase_public.auth.signOut()
    window.location.reload(); // Reload the page on redirect
  }

  const handleUpgradeClick = async () => {
    posthog.capture('upgrade button click', {'email': email});
    window.location.href = '/pricing';
  }

  return (
    <div className="grid min-h-screen h-screen w-full lg:grid-cols-[220px_1fr]">
      <div className="hidden border-subtleBorder bg-cardBg lg:block overflow-hidden h-screen">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center justify-center px-4 lg:h-[60px] lg:px-6">
            <div className="flex items-center justify-center pt-4">
              <a href="/" className="flex justify-center">
                  <div className=" justify-center self-center align-center mx-auto text-3xl font-semibold whitespace-nowrap  text-bluelogo ">Claros</div>
              </a>
            </div>
          </div>
          <div className="flex-1">
            <nav className="grid items-start  pt-4 px-2 lg:px-4">
              <button className="flex items-center text-textGray bg-bgwhite rounded-lg border-2 p-2  mx-auto w-full text-center justify-center transition duration-200 ease-in-out transform hover:scale-105 tracking-normal font-sans mb-12" onClick={() => {
                const urlParams = new URLSearchParams(window.location.search);
                const ref = urlParams.get('ref');
                window.location.href = ref ? `/${ref}` : '/';
                }}>
                <div>New Search</div>
              </button>



              {!session || session.session == null && (
                <div className="flex justify-center items-center">
                            <button className="underline text-[15px] hover:text-lighten transition duration-200 ease-in-out transform text-textGray tracking-normal hover:text-bluelogo cursor-pointer text-center" onClick={() => setShowAuthPrompt(true)}>
                              Sign in to see history
                            </button>
                </div>
              )}

              {session && session.session != null && (
                <>
                <h1 className="text-xl mb-[15px] text-textGray">History</h1>
                  <div className="flex w-full items-start space-x-8 ">
                    <div className="w-full rounded-xl ">
                      <div className="overflow-y-scroll max-h-[50vh]">
                        <UserConversations setSelectedConversation={selectedConversation} uid={userid} />
                      </div>
                    </div>
                  </div>
                </>
              )}

            </nav>
          </div>
        {/* { context.shopUrl.includes("shopWithX") &&
        <div className="flex justify-between items-center my-2 mx-4 text-xs text-slate-500">
          <div>
             We are not affiliated with {context.id}
          </div>
        </div>
        } */}
        <div className="flex justify-between items-center mx-4">

            <a href="https://twitter.com/so_claros" className="rounded-full p-2 text-textGray hover:text-bluelogo " target="_blank">
                <FaTwitter/>
            </a>
            <a href="https://discord.gg/87KJvjpkF8" className="text-textGray  rounded-full p-2 hover:text-bluelogo" target="_blank">
                <FaDiscord/>
            </a>
            <a href="https://www.tiktok.com/@claros_so" className="text-textGray  rounded-full p-2 hover:text-bluelogo" target="_blank">
                <FaTiktok/>
            </a>
            <a href="https://www.instagram.com/claros_ai/" className="text-textGray  rounded-full p-2 hover:text-bluelogo" target="_blank">
                <FaInstagram/>
            </a>
        </div>
        <div className="flex justify-between items-center my-2 mx-4 text-xs text-slate-500">
          <div>
            © 2024 Claros
          </div>
          <a href="/policies/privacy-policy" className="hover:text-bluelogo justify-center text-center" target="_blank">
            Privacy Policy
          </a>
        </div>

        </div>
      </div>
      <div className="flex flex-col bg-bgwhite">
        <header className="flex h-14 items-center gap-4 bg-cardBg lg:bg-bgwhite px-4 lg:h-[60px] lg:px-6 sticky top-0">
          <Sheet>
            <SheetTrigger>
                <Menu className="h-6 w-6 shrink-0 lg:hidden rounded-lg mt-1" />
                <span className="sr-only">Toggle navigation menu</span>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col bg-bgwhite">
              <nav className="grid gap-2 text-lg font-medium mt-12 z-30">
                <button className="flex items-center text-textGray bg-bgwhite rounded-lg border-2 p-2 mr-auto w-full text-center justify-center transition duration-200 ease-in-out transform hover:scale-105 tracking-normal font-sans mb-4 max-w-md" onClick={() => { console.log('Feedback button clicked'); setFeedbackOpened(true);}}>
                  <FaClipboardList className='mr-2 text-center' size={12}/>
                  <span className="text-sm">Leave Feedback</span>
                </button>

              {!session || session.session == null && (
                <div className="flex justify-center items-center">
                            <div className="text-[15px] text-textGray tracking-normal text-center">
                              Sign in to see history
                            </div>
                </div>
              )}

              {session && session.session != null && (
                <>
                <h1 className="text-xl mb-[15px] text-textGray">History</h1>
                  <div className="flex w-full items-start space-x-8 ">
                    <div className="w-full rounded-xl ">
                      <div className="overflow-y-scroll max-h-[50vh]">
                        <UserConversations setSelectedConversation={selectedConversation} uid={userid} />
                      </div>
                    </div>
                  </div>
                </>
              )}


              </nav>
            </SheetContent>
          </Sheet>

          <div className="flex items-center">
            <button className="mr-auto items-center shrink-1 transition duration-200 transform tracking-normal font-sans hover:bg-gray-200 rounded-lg cursor-pointer active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600 border-2 lg:hidden" onClick={() => {
              const urlParams = new URLSearchParams(window.location.search);
              const ref = urlParams.get('ref');
              window.location.href = ref ? `/${ref}` : '/';
            }}>
              <span className="text-sm">New Search</span>
            </button>

            <button onClick={() => { handleUpgradeClick() }} className="ml-2 items-center shrink-1 transition duration-200 transform tracking-normal font-sans hover:bg-gray-200 rounded-lg cursor-pointer active:scale-95 origin-center whitespace-nowrap inline-flex text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600 border-2 lg:hidden">
              <FaRegArrowAltCircleUp className='mr-2 text-center' size={15}/>
              <span className="text-sm"> Upgrade </span>
            </button>
          </div>

          <CategoriesList />

          <div className="flex items-center align-baseline ml-auto  md:space-x-4">
            <button onClick={() => { window.open('https://chromewebstore.google.com/detail/claros-shopping-assistant/nbmffmnaecncglmeofodagbafilnokcj?hl=en', '_blank'); } } className="items-center shrink-1 transition duration-200 transform tracking-normal font-sans hover:bg-gray-200 rounded-lg cursor-pointer active:scale-95 origin-center whitespace-nowrap text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600 border-2 hidden lg:inline-flex ">
              <IoExtensionPuzzleOutline className='mr-2 text-center' size={15}/>
              <span className="text-sm"> Extension </span>
            </button>


            <button onClick={() => { handleUpgradeClick() } } className="items-center shrink-1 transition duration-200 transform tracking-normal font-sans hover:bg-gray-200 rounded-lg cursor-pointer active:scale-95 origin-center whitespace-nowrap text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600 border-2 hidden lg:inline-flex ">
              <FaRegArrowAltCircleUp className='mr-2 text-center' size={15}/>
              <span className="text-sm"> Upgrade </span>
            </button>

            <button onClick={() => { console.log('Feedback button clicked'); setFeedbackOpened(true); }} className="items-center shrink-1 transition duration-200 transform tracking-normal font-sans hover:bg-gray-200 rounded-lg cursor-pointer active:scale-95 origin-center whitespace-nowrap text-sm px-sm font-medium hover:text-bluelogo p-2 text-gray-600 border-2 hidden lg:inline-flex ">
              <FaClipboardList className='mr-2 text-center' size={12}/>
              <span className="text-sm"> Feedback </span>
            </button>

            {!session || session.session == null && (
              <button className="active:scale-95 py-2 px-2 md:px-4 bg-bluelogo text-bgwhite text-sm rounded-md font-sans hover:bg-indigo-600 whitespace-nowrap" onClick={() => setShowAuthPrompt(true)}>
                Sign in
              </button>
            )}


              {session && session.session && (
                <>
                <div className="flex items-center justify-between w-full">
                  {loggedInUsername ? (
                    <>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <a href={`/profile/${loggedInUsername}`} className={buttonStyle} style={{ position: 'relative' }}>
                            <MdPerson size={20} />
                            <span className="align-baseline relative ml-1"> Profile</span>
                            { onboard &&
                              <>
                              <span className="absolute top-0 right-0 translate-x-2 translate-y-[-6px]">
                                <Badge className="bg-bluelogo rounded-full text-white">
                                  !
                                </Badge>
                              </span>
                              </>
                              }
                          </a>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="z-999">
                          <DropdownMenuItem>
                            <button className={buttonStyle} onClick={() => window.location.href = `/profile/${loggedInUsername}`}>
                              <span className="">My Account</span>
                            </button>
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>
                            <button className={buttonStyle} onClick={handleSignOut}>
                              <MdLogout size={20} />
                              <span className="ml-1">Logout</span>
                            </button>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </>
                  ) : (
                    <button className="ml-auto" onClick={handleSignOut}>
                      <img src="/images/User/sign-out.svg" className="h-5 w-5" alt="Sign Out" />
                    </button>
                  )}
                </div>
                </>
              )}
          </div>


        </header>
        {showAuthPrompt && <AuthPrompt sessionId={sessionUUID} showCloseButton={!topicSelected} setShowAuthPrompt={setShowAuthPrompt} topicSelected={topicSelected}/>}

        <ScrollArea className="max-h-[90vh] bg-bgwhite">
        {children}
        </ScrollArea>
      </div>
    </div>
  )
}
